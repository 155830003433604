const InvitationIndex = () => import('../views/InvitationIndex.vue')
const InvitationAccept = () => import('../views/InvitationAccept.vue')
const TrialIndex = () => import('../views/TrialIndex.vue')
const SignupIndex = () => import('../views/SignupIndex.vue')
const SignupHome = () => import('../views/SignupHome.vue')
const SignupOptions = () => import('../views/SignupOptions.vue')
const SignupSuccess = () => import('../views/SignupSuccess.vue')
const TrialProvider = () => import('../views/TrialProvider.vue')
const SignupProvider = () => import('../views/SignupProvider.vue')
const InvitationPassword = () => import('../views/InvitationPassword.vue')
const InvitationHome = () => import('../views/InvitationHome.vue')
const InvitationAuthenticated = () => import('../views/InvitationAuthenticated.vue')
const InvitationProvider = () => import('../views/InvitationProvider.vue')
const InvitationToLogin = () => import('../views/InvitationToLogin.vue')

export default [
    {
        path: '/invitations/review/:invid',
        name: 'invitation-index',
        components: {
            default: InvitationIndex
        },
        children: [
            {
                path: '/',
                name: 'invitation-home',
                components: {
                    default: InvitationHome
                }
            },
            {
                path: 'authenticated',
                name: 'invitation-authenticated',
                components: {
                    default: InvitationAuthenticated
                }
            }
        ]
    },
    {
        path: 'to-login',
        name: 'to-login',
        components: {
            default: InvitationToLogin
        }
    },
    {
        path: '/invitations/review/:invid/password',
        name: 'invitation-password',
        components: {
            default: InvitationPassword
        }
    },
    {
        path: '/invitations/review/:invid/:provider',
        name: 'invitation-provider',
        components: {
            default: InvitationProvider
        }
    },
    {
        path: '/invitations/accept/:invid/',
        name: 'invitation-accept',
        components: {
            default: InvitationAccept
        }
    },
    {
        path: '/enroll/:space/:token',
        name: 'signup-index',
        components: {
            default: SignupIndex
        },
        children: [
            {
                path: '/',
                name: 'signup-home',
                components: {
                    default: SignupHome
                }
            },
            {
                path: 'options',
                name: 'signup-options',
                components: {
                    default: SignupOptions
                }
            }
        ]
    },
    {
        path: '/enroll/:space/:token/success',
        name: 'signup-success',
        components: {
            default: SignupSuccess
        }
    },
    {
        path: '/enroll/:space/:token/:provider',
        name: 'signup-provider',
        components: {
            default: SignupProvider
        }
    },
    {
        path: '/trials/:token',
        name: 'trial',
        components: {
            default: TrialIndex
        }
    },
    {
        path: '/trials/:token/:provider',
        name: 'trial-provider',
        components: {
            default: TrialProvider
        }
    }
]
