<template>
    <div :class="`shepherd-app-${appData.aid}`">
        <v-menu :close-on-content-click="false" offset-x open-on-hover :close-delay="closingDelay">
            <template v-slot:activator="{ on }">
                <v-list-item
                    v-on="on"
                    :to="{
                        name: 'app-open',
                        params: {
                            oid: $route.params.oid,
                            sid: $route.params.sid,
                            iid: $route.params.iid,
                            snid: $route.params.snid,
                            aid: appData.aid,
                            sessionId: appData.session_id
                        },
                        query: $route.query
                    }">
                    <v-list-item-icon v-if="appData.app_type !== undefined">
                        <div style="position: relative">
                            <div
                                style="position: absolute; top: 0; right: 0; z-index: 1000"
                                v-if="appData.mount_handback_to_files_for_hid !== 'None' || appData.mount_handback_for_bid !== 'None'">
                                <div class="mt-n4 mr-n4" style="background-color: var(--v-primary-base)">
                                    <v-icon dark v-if="appData.mount_handback_to_files_for_hid !== 'None'">grading</v-icon>
                                    <v-icon dark v-if="appData.mount_handback_for_bid !== 'None'">visibility</v-icon>
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <v-badge color="light-green" overlap v-if="appData.gpu">
                                    <template v-slot:badge>
                                        <v-icon small class="nudgeUp">mdi-memory mdi-rotate-45</v-icon>
                                    </template>
                                    <img width="35" height="35" :src="appIcon(appData)" />
                                </v-badge>
                                <img v-else width="35" height="35" :src="appIcon(appData)" />
                                <div class="mx-n3 mt-1 mb-n2">
                                    <TheSideBarResourceInfo :currentApp="getAppInfo(appData.aid)" />
                                </div>
                            </div>
                        </div>
                    </v-list-item-icon>
                    <v-list-item-title>Applications</v-list-item-title>
                </v-list-item>
            </template>
            <v-list nav dense>
                <v-list-item>
                    <v-list-item-content>
                        <div class="d-flex flex-column w-100">
                            <div class="d-flex justify-space-between">
                                <span class="caption text-uppercase mr-2">{{ appData.name }}</span>
                                <v-chip v-if="appData.shared === 'true'" small outlined>shared</v-chip>
                            </div>
                            <span class="caption" v-if="appData.gpu">on {{ appData.gpu }} GPU</span>
                            <v-divider class="mt-1" color="grey"></v-divider>
                        </div>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <div class="d-flex align-center justify-space-between">
                            Memory limit:
                            <div>
                                <span class="font-weight-medium ml-1">{{ getAppInfo(appData.aid).limit_memory }}</span>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="secondary" class="ml-2" dark v-on="on" small>info</v-icon>
                                    </template>
                                    <span>If this is breached, the app is restarted. There can be some delay in reporting.</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </v-list-item-content>
                </v-list-item>
                <template v-if="appData.ipc_mode !== 'none'">
                    <v-list-item>
                        <v-list-item-content>
                            <div class="d-flex align-center justify-space-between">
                                <div>Exposed in:</div>
                                <div>{{ getIpcName(appData.ipc_mode) }}</div>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <div class="d-flex align-center justify-space-between">
                                <div class="pr-4">Hostname:</div>
                                <code class="pl-3 py-1">
                                    {{ truncateMid(appData.ipc_hostname, 7) }}
                                    <copy-to-clipboard :textToCopy="appData.ipc_hostname" buttonName="copy to clipboard" :iconButton="true"></copy-to-clipboard>
                                </code>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-3" color="grey"></v-divider>
                </template>
                <v-list-item-group>
                    <v-list-item
                        :to="{
                            name: 'app-open',
                            params: {
                                oid: $route.params.oid,
                                sid: $route.params.sid,
                                iid: $route.params.iid,
                                snid: $route.params.snid,
                                aid: appData.aid,
                                sessionId: appData.session_id
                            },
                            query: $route.query
                        }"
                        active-class="hide-active-state"
                        target="_blank">
                        <v-icon small class="mr-1">launch</v-icon>
                        Open in new tab
                    </v-list-item>
                    <v-list-item
                        v-if="$appConfig.VUE_APP_INTERCOM_ID"
                        @click="showNewMessageIntercom('Hi Nuvolos Team, \nI would like to ask your help regarding the following:\n', userInfo)">
                        <v-icon small class="mr-1">help</v-icon>
                        Ask for help / report an issue
                    </v-list-item>
                    <v-list-item @click="$router.push({ name: 'snapshot-applications', query: { resetAid: appData.aid } })">
                        <v-icon small class="mr-1">auto_fix_high</v-icon>
                        Clear user application settings
                    </v-list-item>
                    <stop-application
                        :app="appData"
                        :appName="appData.name"
                        :isShared="getAppInfo(appData.aid).shared === 'true'"
                        :sessionId="appData.session_id">
                        <v-list-item>
                            <v-icon small class="mr-1">stop</v-icon>
                            Stop
                        </v-list-item>
                    </stop-application>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { intercomMethods } from '@/mixins/intercom'
import Shepherd from 'shepherd.js'
import { truncateMid, ipcOptions } from '@/utils'
const TheSideBarResourceInfo = () => import('./TheSideBarResourceInfo.vue')
const StopApplication = () => import('@/modules/application/components/TheApplicationStopDialog')
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    mixins: [appTypeAndImageLink, intercomMethods],
    components: {
        TheSideBarResourceInfo,
        StopApplication,
        CopyToClipboard
    },
    props: {
        appData: Object
    },
    data() {
        return {
            closingDelay: 100
        }
    },
    computed: {
        ...mapGetters('appStore', ['runningDeployments', 'startingDeployments']),
        ...mapState(['userOrgs', 'userInfo'])
    },
    methods: {
        getAppInfo(aid) {
            return this.runningDeployments.concat(this.startingDeployments).find(a => {
                return a.aid.toString() === aid.toString()
            })
        },
        truncateMid(text, length) {
            return truncateMid(text, length)
        },
        getIpcName(value) {
            const ipc = ipcOptions.find(ipc => ipc.value === value)
            return ipc.text
        }
    },
    mounted() {
        if (this.appData.mount_handback_for_bid !== 'None') {
            this.appHelpTour = new Shepherd.Tour({
                defaultStepOptions: {
                    cancelIcon: {
                        enabled: true
                    },
                    classes: 'secondary--text',
                    scrollTo: { behavior: 'smooth' }
                }
            })
            this.appHelpTour.addStep({
                title: 'Running in Review Mode',
                text: 'This application is running in <b>review mode</b>, you can open files handed back by the instructor but cannot save changes or create new files! <br><br>If you would like to work on existing files in your workspace, stop and restart this application to return to normal mode.',
                attachTo: {
                    element: `.shepherd-app-${this.appData.aid}`,
                    on: 'right'
                },
                buttons: [
                    {
                        action() {
                            return this.complete()
                        },
                        text: `Got it!`
                    }
                ],
                id: 'app-help'
            })
            this.appHelpTour.start()
        }
    }
}
</script>

<style scoped>
.hide-active-state::before {
    background: transparent !important;
}

::v-deep .nudgeUp {
    margin-top: -2px;
}
</style>
