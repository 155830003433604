<template>
    <v-btn v-if="fetchingUserInfo" :loading="fetchingUserInfo" block color="primary"></v-btn>
    <v-btn
        v-else-if="!userInfo"
        block
        color="primary"
        :to="{ name: 'invitation-provider', params: { invid: this.$route.params.invid, provider: getAuthenticatedProvider() } }">
        create account
    </v-btn>
    <v-btn v-else block color="primary" :to="{ name: 'invitation-accept', params: { invid: this.$route.params.invid } }">continue</v-btn>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'InvitationAuthenticated',
    props: {},
    data() {
        return {}
    },
    created() {},
    computed: {
        ...mapState(['userInfo', 'fetchingUserInfo'])
    },
    methods: {
        async getAuthenticatedProvider() {
            const token = await this.$auth.getTokenSilently()
            const parsedJWT = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('binary'))
            const provider = parsedJWT.sub.split('|')[0] === 'google-oauth2' ? 'google' : 'satosa'
            return provider
        }
    }
}
</script>
