<template>
    <v-dialog v-model="dialog" width="600" persistent scrollable>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="w-100">
                <slot></slot>
            </div>
        </template>
        <v-card class="pa-0">
            <v-card-title class="pa-0">
                <v-tabs v-model="tab" fixed-tabs>
                    <v-tab>Application settings</v-tab>
                    <v-tab>Add-ons</v-tab>
                </v-tabs>
            </v-card-title>
            <v-card-text>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <dashboard-loader class="mt-7" v-if="configLoading">Loading settings</dashboard-loader>
                        <v-card flat tile v-else>
                            <v-card-text>
                                <v-slider
                                    label="Inactivity limit"
                                    thumb-label="always"
                                    :hint="limitHint"
                                    max="24"
                                    min="1"
                                    v-model="inactivityLimit"
                                    class="mt-10"
                                    persistent-hint
                                    :disabled="isTrial"></v-slider>
                                <v-checkbox
                                    hint="In shared mode multiple users can connect to the same application, allowing collaborative editing in certain applications (e.g. JupyterLab >= 4.0). Currently doesn't support querying tables."
                                    label="Run in shared mode"
                                    :disabled="!sharedEditable"
                                    v-model="isShared"
                                    persistent-hint></v-checkbox>
                                <v-checkbox
                                    hint="Persisting session data enables you to retain data in the application's home folder (notably application-specific settings) between restarts. However, not persisting this data will usually come with extra performance benefit."
                                    label="Persist sessions data in home area between restarts"
                                    v-model="persistentHomeArea"
                                    persistent-hint></v-checkbox>
                                <v-select
                                    class="mt-10"
                                    :items="ipcOptions"
                                    label="Other applications may connect to this app from"
                                    hint="IPC mode signal the Inter-Pod Communication mode for the app: “instance“ means any app in the same instance may connect to this app, “space“ means any app in the same space, and so on. The value “none“ means that no other application can connect to this app."
                                    v-model="ipcMode"
                                    hide-details
                                    outlined></v-select>
                                <v-alert v-if="ipcMode !== 'none'" text color="info" class="mt-3 mb-0">
                                    <v-row align="center">
                                        <v-col class="shrink"><v-icon color="info" right>mdi-connection</v-icon></v-col>
                                        <v-col class="grow">
                                            <p class="mb-4">Hostname of this application will be:</p>
                                            <div>
                                                <code class="pl-5 py-3">
                                                    {{ ipcHostname }}
                                                    <copy-to-clipboard
                                                        :textToCopy="ipcHostname"
                                                        buttonName="copy to clipboard"
                                                        :iconButton="true"></copy-to-clipboard>
                                                </code>
                                            </div>
                                        </v-col>
                                        <!-- <v-col class="shrink">
                                            <v-btn outlined :href="restingDocLink" target="_blank" color="primary">
                                                <v-icon left>{{ documentationIcon }}</v-icon>
                                                documentation
                                            </v-btn>
                                        </v-col> -->
                                    </v-row>
                                </v-alert>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat tile>
                            <v-card-text class="px-1">
                                <v-expansion-panels v-model="expandedAddon">
                                    <v-expansion-panel v-for="(addon, idx) in addons" :key="idx">
                                        <v-expansion-panel-header disable-icon-rotate>
                                            <div>
                                                {{ addon.name }}
                                                <v-icon small v-if="expandedAddon === idx">mdi-chevron-up</v-icon>
                                                <v-icon small v-else>mdi-chevron-down</v-icon>
                                            </div>
                                            <v-spacer></v-spacer>
                                            <template v-slot:actions v-if="!addon.enabled">
                                                <v-btn
                                                    text
                                                    dense
                                                    color="primary"
                                                    @click.stop="enableAddon(addon)"
                                                    :disabled="!addon.editable"
                                                    :loading="loaders.includes(addon.name)">
                                                    Add
                                                </v-btn>
                                            </template>
                                            <template v-slot:actions v-else-if="addon.enabled && !addon.editable">
                                                <v-btn text dense color="error" disabled>Required</v-btn>
                                            </template>
                                            <template v-slot:actions v-else>
                                                <v-btn text dense :loading="loaders.includes(addon.name)" @click.stop="disableAddon(addon)">Remove</v-btn>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-container>
                                                <div class="float-left mr-3" v-if="addon.icon_url">
                                                    <v-img max-width="4em" :src="addon.icon_url" />
                                                </div>
                                                <div class="caption" v-html="addon.description"></div>
                                                <div v-if="addon.doc_link" class="text-right mt-2">
                                                    <a :href="addon.doc_link" target="_blank">Go to documentation</a>
                                                </div>
                                            </v-container>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions v-if="tab === 1">
                <v-spacer />
                <v-btn text color="secondary" @click="dialog = false">Close</v-btn>
            </v-card-actions>
            <v-card-actions v-else>
                <v-spacer />
                <v-btn @click="dialog = false" text>Cancel</v-btn>
                <v-btn @click="saveConfig" text color="primary" :loading="savingSettings" :disabled="!canSave">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { ipcOptions } from '@/utils'
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')
const DashboardLoader = () => import('@/components/DashboardLoader.vue')

export default {
    props: {
        aid: Number,
        canSave: Boolean,
        isTrial: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CopyToClipboard,
        DashboardLoader
    },
    data() {
        return {
            dialog: false,
            isShared: false,
            savingSettings: false,
            inactivityLimit: 1,
            persistentHomeArea: false,
            sharedEditable: false,
            ipcHostname: '',
            ipcOptions,
            ipcMode: ipcOptions[0].value,
            tab: 0,
            addons: [],
            loaders: [],
            expandedAddon: null,
            configLoading: false
        }
    },
    computed: {
        limitHint() {
            return this.isTrial
                ? 'Cannot change inactivity limit in trial.'
                : 'The number of inactive hours before the app gets shut down automatically (unscaled only).'
        }
    },
    methods: {
        saveConfig() {
            this.savingSettings = true
            this.$axios
                .put(`/applications/${this.$props.aid}/config`, {
                    NCU: this.NCU,
                    shared: this.isShared,
                    inactivity_limit: this.inactivityLimit,
                    home_mount_type: this.persistentHomeArea ? 'ceph_direct' : 'emptydir',
                    shared_editable: this.sharedEditable,
                    ipc_mode: this.ipcMode
                })
                .then(() => {
                    this.dialog = false
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Settings saved, stop & start the application for it to take effect.',
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(err => {
                    console.log(err)
                    this.$store.dispatch('showSnackBar', { snackBarText: 'There was an error whilst saving settings', snackBarIcon: 'error' })
                })
                .finally(() => {
                    this.savingSettings = false
                })
        },
        fetchConfig() {
            this.configLoading = true
            this.$axios
                .get(`/applications/${this.$props.aid}/config`)
                .then(({ data }) => {
                    this.isShared = data.shared
                    this.inactivityLimit = data.inactivity_limit
                    this.persistentHomeArea = data.home_mount_type === 'ceph_direct'
                    this.sharedEditable = data.shared_editable
                    this.addons = data.addons
                    this.ipcHostname = data.ipc_hostname
                    this.ipcMode = data.ipc_mode
                })
                .catch(err => {
                    console.log(err)
                    this.$store.dispatch('showSnackBar', { snackBarText: 'There was an error whilst fetching application settings', snackBarIcon: 'error' })
                })
            this.configLoading = false
        },
        enableAddon(addon) {
            this.sendAddonUpdate(addon, true)
        },
        disableAddon(addon) {
            this.sendAddonUpdate(addon, false)
        },
        sendAddonUpdate(addon, enable) {
            this.loaders.push(addon.name)
            addon.enabled = enable
            this.$axios
                .patch(`/applications/${this.$props.aid}/config`, { addons: [addon] })
                .then(() => {
                    this.fetchConfig()
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `${addon.name} successfully ${addon.enabled ? 'added. It will be available after restart.' : 'removed.'}`,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(error => {
                    console.error(error)
                    this.$store.dispatch('showSnackBar', { snackBarText: 'There was an error enabling addon', snackBarIcon: 'error' })
                })
                .finally(() => {
                    this.loaders = this.loaders.filter(loader => loader !== addon.name)
                })
        }
    },
    watch: {
        dialog(open) {
            if (!open) return
            this.fetchConfig()
        }
    }
}
</script>
