<template>
    <div>
        <v-banner class="mb-5" v-if="currentSpaceType === spaceTypes.VENDOR_SPACE && currentTables.length" single-line>
            <v-avatar slot="icon" color="white" size="30"><v-icon color="info">info</v-icon></v-avatar>
            This dataset contains dataset tables.
            <template v-slot:actions>
                <v-btn
                    :to="{
                        name: 'snapshot-tables',
                        params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                    }"
                    text
                    color="primary">
                    Browse tables
                </v-btn>
                <v-btn href="https://docs.nuvolos.cloud/features/database-integration/view-tables" target="_blank" text color="primary">
                    How to handle data in Nuvolos?
                </v-btn>
            </template>
        </v-banner>
        <v-alert v-if="isSpaceWaking" text color="info" class="ma-4 mb-0">
            <v-row align="center">
                <v-col class="shrink"><v-icon color="info" right>mdi-information</v-icon></v-col>
                <v-col class="grow">
                    Files are not shown in the navigator until every instance has been woken up. Applications will be started as soon as their instance is woken
                    up.
                </v-col>
            </v-row>
        </v-alert>
        <increase-storage-quota></increase-storage-quota>
        <v-card flat :disabled="isSpaceResting || isSpaceWaking">
            <v-card-title>
                <TheSnapshotMoveAndCopyFiles
                    :selectedFiles="filesToMoveOrCopy"
                    :operationType="copyOrMoveOperation"
                    :folderTypeSelected="folderTypeSelected"
                    @clearCopyAndMoveFiles="clearCopyAndMoveFiles()"
                    @removeFileFromMoveCopyList="removeFileFromMoveCopyList($event.fid)" />
                <div class="d-flex w-100">
                    <span class="secondary--text font-weight-bold">Files</span>
                    <v-spacer></v-spacer>
                    <the-snapshot-usage-statistics-dialog
                        :changeFileType="changeFileType"
                        v-if="!fetchingSnapshotQuota && snapshotQuota.bytes_pcent !== null && isDevelopment && snapshotFilesystemPrefix && !isSpaceRested">
                        <v-hover v-slot="{ hover }">
                            <v-card class="pointer" :class="{ 'grey lighten-4': hover }" elevation="0">
                                <v-card-text>
                                    <v-progress-linear
                                        rounded
                                        height="6"
                                        :value="quotaPercentageUsage"
                                        :color="quotaPercentageUsage <= 90 ? 'primary' : 'error'"
                                        class="mb-2"></v-progress-linear>
                                    <div class="caption font-weight-medium d-flex align-center">
                                        {{ `${quotaPercentageUsage}% of ${humanFileSize(snapshotQuota.bytes_quota)}` }}
                                        used
                                        <v-icon right small>mdi-open-in-new</v-icon>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </the-snapshot-usage-statistics-dialog>
                </div>
            </v-card-title>

            <v-card-text>
                <v-skeleton-loader v-if="filesFetching" class="mx-auto" type="table"></v-skeleton-loader>

                <v-alert v-else-if="isSpaceRested && isDevelopment" text color="info" class="ma-4 mb-0">
                    <v-row align="center">
                        <v-col class="shrink"><v-icon color="info" right>mdi-information</v-icon></v-col>
                        <v-col class="grow">
                            <div><b>Rested state</b></div>
                            Files are not available in the Current State of this instance. Wake up the space to work with its files or browse snapshots directly
                            to download/distribute them.
                        </v-col>
                    </v-row>
                </v-alert>

                <v-banner v-else-if="!snapshotFilesystemPrefix && !isColdStorage && !isSpaceInAnyRestState" single-line>
                    <v-avatar slot="icon" color="white" size="60">
                        <v-icon x-large icon="info" color="info">info</v-icon>
                    </v-avatar>
                    <div class="d-flex flex-column">
                        <template v-if="isInstanceEditor && isDevelopment">
                            <span class="font-weight-bold secondary--text">Archived state</span>
                            <p>
                                Files are not available in the Current State of this instance. To access files, you may either
                                <a @click="goToSnapshots()">restore</a>
                                a snapshot to Current State or browse files of snapshots.
                            </p>
                        </template>
                    </div>
                </v-banner>
                <div v-else>
                    <div class="d-flex align-center">
                        <div class="mr-2">
                            <file-upload v-if="isDevelopment && !isSpaceRested" :endpoint="endpoint" :disabled="filesFetching || isSpaceArchived" />
                        </div>
                        <div class="mr-2">
                            <create-folder v-if="isDevelopment && !isSpaceRested" :disabled="filesFetching || isSpaceArchived" :path="endpoint" />
                        </div>
                        <v-switch v-model="showHidden" hide-details label="Hidden Files" class="my-0 pa-0 mx-2"></v-switch>
                        <v-divider v-if="currentFiles.length" vertical class="mx-3"></v-divider>
                        <template v-if="selected.length > 0">
                            <v-btn @click="addToStaging(selected, 'selected')" class="justify-start mr-2">
                                <v-icon left>share</v-icon>
                                stage selected ({{ selected.length }})
                            </v-btn>
                            <v-btn class="mr-2" @click="downloadFile(selected)">
                                <v-icon left>mdi-download</v-icon>
                                download selected
                            </v-btn>
                        </template>
                        <v-btn v-if="tableData.length > 0" class="mr-2" @click="downloadFile([currentFolder])">
                            <v-icon left>mdi-download</v-icon>
                            download all
                        </v-btn>
                        <v-menu v-model="multipleFilesActionsMenu" v-if="currentFiles.length" offset-y bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" color="secondary" icon dark class="caption mr-2">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense nav class="py-4">
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            @click="addToStaging(currentFiles, 'selected')"
                                            :disabled="!currentFiles.length"
                                            color="secondary"
                                            class="justify-start"
                                            block
                                            text>
                                            <v-icon left>share</v-icon>
                                            stage all ({{ currentFiles.length }})
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            @click="addToStaging(selected, 'selected')"
                                            :disabled="!selected.length"
                                            color="secondary"
                                            block
                                            text
                                            class="justify-start">
                                            <v-icon left>share</v-icon>
                                            stage selected ({{ selected.length }})
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider class="my-3"></v-divider>
                                <v-list-item>
                                    <v-list-item-title>
                                        <v-btn
                                            v-if="selected.length === 0"
                                            color="secondary"
                                            class="justify-start"
                                            text
                                            block
                                            @click="downloadFile([currentFolder])">
                                            <v-icon left>mdi-download</v-icon>
                                            download all
                                        </v-btn>
                                        <v-btn v-else color="secondary" class="justify-start" text block @click="downloadFile(selected)">
                                            <v-icon left>mdi-download</v-icon>
                                            download selected
                                        </v-btn>
                                    </v-list-item-title>
                                    <v-list-item-action v-if="selected.length > 10">
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                            </template>
                                            <span>Downloading multiple files is possible only for a maximum of 10 files.</span>
                                        </v-tooltip>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-list-item v-if="currentSpace.vimeo_enabled && isSpaceAdmin && !isSpaceArchived && isAllVideo(selected)">
                                    <v-list-item-title>
                                        <vimeo-upload-dialog :videos="selected" @dismissed="fetchFiles()">
                                            <v-btn @click="multipleFilesActionsMenu = false" block color="secondary" class="justify-start" text>
                                                <v-icon left>mdi-filmstrip-box-multiple</v-icon>
                                                add to video library
                                            </v-btn>
                                        </vimeo-upload-dialog>
                                    </v-list-item-title>
                                </v-list-item>
                                <template v-if="isDevelopment && !isSpaceRested">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn
                                                @click="addToMoveCopyList(selected, 'move', true)"
                                                :disabled="!selected.length"
                                                color="secondary"
                                                text
                                                block
                                                class="justify-start">
                                                <v-icon left>mdi-folder-move</v-icon>
                                                move
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn
                                                @click="addToMoveCopyList(selected, 'copy', true)"
                                                :disabled="!selected.length"
                                                color="secondary"
                                                text
                                                block
                                                class="justify-start">
                                                <v-icon left>content_copy</v-icon>
                                                copy
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-divider class="my-3"></v-divider>
                                </template>
                                <v-list-item v-if="isDevelopment && !isColdStorage && !isSpaceRested">
                                    <delete-button
                                        objectType="file"
                                        :buttonName="getDeleteLabel"
                                        protocol="POST"
                                        :request-body="{ fids: selectedFileIds, mode: 0 }"
                                        apiURL="/files/delete_async"
                                        :disabled="selected.length === 0"
                                        :isAsyncDeletion="true"
                                        :showDialog="true"
                                        :warningText="confirmQuestion(0)"
                                        @error="errorMessage($event.error)"
                                        @deleting="emptySelected(!$event.value)"
                                        fetchString="snapshotStore/fetchCurrentFiles"
                                        :objectName="selectedFileNames.toString()"></delete-button>
                                </v-list-item>
                                <template
                                    v-if="
                                        isSpaceAdmin && !isHomeFilesArea && currentSpaceType !== spaceTypes.RESEARCH_SPACE && !isColdStorage && !isSpaceRested
                                    ">
                                    <v-list-item>
                                        <delete-button
                                            objectType="file"
                                            :buttonName="getDeleteForOthersLabel"
                                            protocol="POST"
                                            :request-body="{ fids: selectedFileIds, mode: 2 }"
                                            apiURL="/files/delete_async"
                                            :disabled="selected.length === 0"
                                            :isAsyncDeletion="true"
                                            :showDialog="true"
                                            :warningText="confirmQuestion(2)"
                                            @error="errorMessage($event.error)"
                                            @deleting="emptySelected(!$event.value)"
                                            fetchString="snapshotStore/fetchCurrentFiles"
                                            :objectName="selectedFileNames.toString()"></delete-button>
                                    </v-list-item>
                                    <v-list-item v-if="isDevelopment && isMasterInstance && !isSpaceRested">
                                        <delete-button
                                            objectType="file"
                                            buttonName="Delete for all"
                                            protocol="POST"
                                            :request-body="{ fids: selectedFileIds, mode: 1 }"
                                            apiURL="/files/delete_async"
                                            :disabled="selected.length === 0"
                                            :isAsyncDeletion="true"
                                            :showDialog="true"
                                            :warningText="confirmQuestion(1)"
                                            @error="errorMessage($event.error)"
                                            @deleting="emptySelected(!$event.value)"
                                            fetchString="snapshotStore/fetchCurrentFiles"
                                            :objectName="selectedFileNames.toString()"></delete-button>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                        <v-spacer></v-spacer>
                        <v-text-field
                            prepend-inner-icon="mdi-filter"
                            autofocus
                            hide-details
                            solo
                            flat
                            background-color="grey lighten-4"
                            dense
                            label="Filter files..."
                            v-model="search"
                            clearable
                            class="mr-2"></v-text-field>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="fetchFiles()" icon v-on="on" :loading="filesFetching">
                                    <v-icon>refresh</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Files</span>
                        </v-tooltip>
                    </div>
                    <v-data-table
                        :headers="headers"
                        :items="tableData"
                        show-select
                        v-model="selected"
                        item-key="fid"
                        :options="tableOptions"
                        :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
                        :search="search"
                        :loading="filesFetching"
                        class="shepherd-staging-step-1">
                        <template v-slot:no-data>
                            <span v-if="isDevelopment && pathArray.length === 1 && !currentFiles.length && !isSpaceRested">
                                Currently you don't have files. Consider adding new files via the "Upload" button above or you can also
                                <a href="https://docs.nuvolos.cloud/features/file-system-and-storage/synchronize-with-dropbox" target="_blank">
                                    set up Dropbox integration.
                                </a>
                            </span>
                            <span v-else>Currently you don't have files. Use the "Hidden Files" switch above to display any hidden files.</span>
                        </template>
                        <template v-slot:top="{ options, pagination, updateOptions }">
                            <div class="d-flex align-center">
                                <div v-if="!filesFetching && (snapshotFilesystemPrefix || isColdStorage || isSpaceRested)">
                                    <v-snackbar v-model="stageSuggestionSnackbar" :timeout="snackbarTimeout" color="info">
                                        <v-icon left dark>info</v-icon>
                                        Add uploaded files to stage to share with others?
                                        <v-btn color="white" outlined @click="addToStaging(lastUploadedFiles, 'uploaded')">Stage</v-btn>
                                        <v-btn color="white" text @click="discardUploaded">Discard</v-btn>
                                    </v-snackbar>
                                    <v-sheet color="grey lighten-4" rounded class="d-flex align-center">
                                        <v-menu :disabled="filesFetching" offset-y>
                                            <template v-slot:activator="{ on }">
                                                <v-btn text v-on="on" e2e-snapshot-file-area small class="mx-2">
                                                    <span class="text-uppercase">
                                                        {{
                                                            folderTypeSelected === folderTypesLabels.WORKSPACE_FILES
                                                                ? userWorkAreas.WORKSPACE
                                                                : userWorkAreas.PERSONAL
                                                        }}
                                                    </span>
                                                    <v-icon small right>keyboard_arrow_down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list nav dense>
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-list-item v-on="on" @click="changeFileType(folderTypesLabels.WORKSPACE_FILES)">
                                                            <v-list-item-title>Workspace</v-list-item-title>
                                                        </v-list-item>
                                                    </template>
                                                    <span>Public files visible to all instance editors/viewers.</span>
                                                </v-tooltip>
                                                <v-tooltip right v-if="!isInstanceViewer">
                                                    <template v-slot:activator="{ on }">
                                                        <v-list-item v-on="on" @click="changeFileType(folderTypesLabels.PERSONAL_FILES)">
                                                            <v-list-item-title>Personal</v-list-item-title>
                                                        </v-list-item>
                                                    </template>
                                                    <span>Files visible to you only.</span>
                                                </v-tooltip>
                                            </v-list>
                                        </v-menu>
                                        <v-divider vertical></v-divider>
                                        <v-breadcrumbs large :items="dirPaths" class="ma-0 py-2 px-4">
                                            <template v-slot:item="props">
                                                <a
                                                    @click="
                                                        $store.dispatch('snapshotStore/setTreeLevel', {
                                                            snid: $route.params.snid,
                                                            level: props.item.index + 1
                                                        })
                                                    ">
                                                    <span class="secondary--text" v-if="props.item.index > 0">{{ props.item.text }}</span>
                                                    <v-icon v-else-if="dirPaths.length === 1" color="secondary">folder</v-icon>
                                                    <v-icon v-else color="secondary">mdi-folder-open</v-icon>
                                                </a>
                                            </template>
                                        </v-breadcrumbs>
                                    </v-sheet>
                                </div>
                                <v-spacer></v-spacer>
                                <v-data-footer
                                    :pagination="pagination"
                                    :options="options"
                                    @update:options="updateOptions"
                                    :itemsPerPageOptions="itemsPerPageOptions"
                                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                                    class="no-border" />
                            </div>
                        </template>
                        <template slot="body.prepend" v-if="pathArray.length > 1">
                            <tr>
                                <td></td>
                                <td>
                                    <v-btn
                                        text
                                        block
                                        class="justify-start"
                                        @click="
                                            $store.dispatch('snapshotStore/setTreeLevel', {
                                                snid: $route.params.snid,
                                                level: pathArray.length - 1
                                            })
                                        ">
                                        <v-icon left color="secondary">$unixTraversalUp</v-icon>
                                        &nbsp;
                                    </v-btn>
                                </td>
                                <td colspan="3"></td>
                            </tr>
                        </template>
                        <template v-slot:[`item.short_id`]="{ item }">
                            <v-btn v-if="item.type === nuvolosObjectTypes.FOLDER" text class="text-none" @click="openDirectory(item.short_id)">
                                <v-icon left color="secondary">folder</v-icon>
                                {{ item.short_id }}
                            </v-btn>
                            <v-menu v-else offset-y right min-width="0">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" text class="text-none" @click="fetchIsEditableFile(item)">
                                        <v-icon left color="primary">{{ fileIcon(item.type, item.short_id) }}</v-icon>
                                        {{ item.short_id }}
                                        <v-icon right class="showOnHover">mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav class="py-4">
                                    <v-list-item v-if="item.short_id && item.short_id.toLowerCase().endsWith('.pdf')">
                                        <v-list-item-title>
                                            <pdf-viewer :pdfUrl="previewBlobUrl" :longId="previewLongId">
                                                <v-btn
                                                    @click="previewFile(item)"
                                                    color="secondary"
                                                    class="justify-start"
                                                    text
                                                    :loading="downloadingFileForPreview"
                                                    block>
                                                    <v-icon left>mdi-eye</v-icon>
                                                    view
                                                </v-btn>
                                            </pdf-viewer>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.short_id && item.short_id.toLowerCase().endsWith('.ipynb')">
                                        <v-list-item-title>
                                            <notebook-viewer :notebookContent="notebookContent" :longId="nbFile" :loading="downloadingFileForPreview">
                                                <v-btn @click="previewNotebook(item)" color="secondary" class="justify-start" text block>
                                                    <v-icon left>mdi-eye</v-icon>
                                                    view
                                                </v-btn>
                                            </notebook-viewer>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn color="secondary" class="justify-start" text block @click="downloadFile(item)">
                                                <v-icon left>mdi-download</v-icon>
                                                download
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="isZipFile(item)">
                                        <v-list-item-title>
                                            <v-btn :loading="extractingZip" @click="extractZip(item)" color="secondary" text block class="justify-start">
                                                <v-icon left>mdi-folder-zip-outline</v-icon>
                                                extract all
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="isDevelopment && !isSpaceArchived && !isSpaceRested">
                                        <v-list-item-title>
                                            <the-snapshot-file-editor
                                                :endpoint="endpoint"
                                                :fileData="item"
                                                :language="fileLanguage(item)"
                                                :isMarkdownFile="fileLanguage(item) === editorLanguages.md"
                                                :isBlock="true"
                                                :disabled="!isEditableFile(item)"
                                                :loading="isEditableFileFetching"></the-snapshot-file-editor>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="isDevelopment && canEditInJupyter(item) && !isSpaceArchived && !isSpaceRested">
                                        <v-menu open-on-hover offset-x nudge-top="14">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn text block v-bind="attrs" v-on="on" color="secondary">
                                                    <v-icon left>mdi-television-play</v-icon>
                                                    <span class="flex-grow-1 text-left">Open in</span>
                                                    <v-icon right>mdi-chevron-right</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item v-for="app in jupyterApps" :key="app.aid">
                                                    <v-btn
                                                        color="secondary"
                                                        text
                                                        block
                                                        class="justify-start"
                                                        :to="{
                                                            name: 'app-open',
                                                            params: {
                                                                aid: app.aid,
                                                                filePath: createFilePath(item, app),
                                                                reset: true
                                                            }
                                                        }">
                                                        <img
                                                            src="https://dlcfc4rxk1sfk.cloudfront.net/nuvolos/app_logos/jupyter-logo.svg"
                                                            height="16px"
                                                            width="16px"
                                                            class="mr-2 ml-n1" />
                                                        {{ app.long_id }}
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-slot:[`item.last_modified_timestamp`]="{ item }">
                            <FileStatus :fileData="item"></FileStatus>
                        </template>
                        <template v-slot:[`item.size`]="{ item }">
                            <div class="d-flex justify-end grey--text">
                                <span>{{ humanFileSize(item.size) }}</span>
                            </div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn @click="addToStaging([item], 'selected')" color="secondary" class="mr-2 showOnHover" small outlined text>
                                <v-icon left>share</v-icon>
                                stage
                            </v-btn>
                            <v-menu v-model="dropdownMenu[`file_${item.fid}`]" left offset-x>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" @click="fetchIsEditableFile(item)" text color="secondary" class="mr-3" small>
                                        <v-icon>more_horiz</v-icon>
                                    </v-btn>
                                </template>
                                <v-list nav dense class="py-4">
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn
                                                :download="item.type === nuvolosObjectTypes.FOLDER ? `${item.short_id}.zip` : item.short_id"
                                                @click="downloadFile(item)"
                                                color="secondary"
                                                text
                                                block
                                                class="justify-start">
                                                <v-icon left>mdi-download</v-icon>
                                                download
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.type !== nuvolosObjectTypes.FOLDER && currentFileStatus(item) !== fileStatusTypes.UNSAVED">
                                        <v-list-item-title>
                                            <v-btn block @click="showFileVersions(item)" color="secondary" text>
                                                <div class="d-flex align-center justify-start w-100">
                                                    <v-icon left>mdi-file-compare</v-icon>
                                                    versions
                                                </div>
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="currentSpace.vimeo_enabled && isSpaceAdmin && !isSpaceArchived && item.is_video">
                                        <v-list-item-title>
                                            <vimeo-upload-dialog :videos="[item]" @dismissed="fetchFiles()">
                                                <v-btn @click="closeMenu(item)" block color="secondary" text>
                                                    <div class="d-flex align-center justify-start w-100">
                                                        <v-icon left>mdi-filmstrip-box-multiple</v-icon>
                                                        add to video library
                                                    </div>
                                                </v-btn>
                                            </vimeo-upload-dialog>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <template v-if="isDevelopment && !isSpaceRested">
                                        <v-list-item v-if="item.short_id && item.short_id.toLowerCase().endsWith('.pdf')">
                                            <v-list-item-title>
                                                <pdf-viewer :pdfUrl="previewBlobUrl" :longId="previewLongId">
                                                    <v-btn
                                                        @click="previewFile(item)"
                                                        color="secondary"
                                                        text
                                                        :loading="downloadingFileForPreview"
                                                        block
                                                        class="justify-start">
                                                        <v-icon left>mdi-eye</v-icon>
                                                        view
                                                    </v-btn>
                                                </pdf-viewer>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-divider class="my-3"></v-divider>
                                        <v-list-item>
                                            <v-list-item-title>
                                                <rename-button
                                                    :selected="[item]"
                                                    objectType="file"
                                                    @finished="emptySelected($event.value)"
                                                    :dispatchFunction="
                                                        () => {
                                                            $store.dispatch('snapshotStore/fetchCurrentFiles', {
                                                                id: $route.params.snid,
                                                                route: $route,
                                                                setFetchingStatus: true
                                                            })
                                                        }
                                                    "></rename-button>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-title>
                                                <v-btn @click="addToMoveCopyList([item], 'move')" color="secondary" text block class="justify-start">
                                                    <v-icon left>mdi-folder-move</v-icon>
                                                    move
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="!isSpaceArchived">
                                            <v-list-item-title>
                                                <the-snapshot-file-editor
                                                    :endpoint="endpoint"
                                                    :fileData="item"
                                                    :language="fileLanguage(item)"
                                                    :isMarkdownFile="fileLanguage(item) === editorLanguages.md"
                                                    :isBlock="true"
                                                    :disabled="!isEditableFile(item)"
                                                    :loading="isEditableFileFetching"></the-snapshot-file-editor>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item class="d-flex">
                                            <v-btn @click="addToMoveCopyList([item], 'copy')" color="secondary" class="flex-grow-1 justify-start" text>
                                                <v-icon left>content_copy</v-icon>
                                                copy
                                            </v-btn>
                                            <v-menu open-on-hover offset-x nudge-top="14">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-chevron-right</v-icon></v-btn>
                                                </template>
                                                <v-list>
                                                    <copy-to-clipboard
                                                        :textToCopy="copyToClipboard(item)"
                                                        :buttonName="`Copy ${isFolder(item) ? 'folder' : 'file'} name`"
                                                        type="listItem"></copy-to-clipboard>
                                                    <copy-to-clipboard
                                                        :textToCopy="copyToClipboard(item, true)"
                                                        :buttonName="`Copy ${isFolder(item) ? 'folder' : 'file'} path`"
                                                        type="listItem"></copy-to-clipboard>
                                                </v-list>
                                            </v-menu>
                                        </v-list-item>
                                        <v-list-item v-if="item.type !== nuvolosObjectTypes.FOLDER">
                                            <v-list-item-title>
                                                <v-btn block @click="duplicateFile(item)" color="secondary" text>
                                                    <div class="d-flex align-center justify-start w-100">
                                                        <v-icon left>mdi-content-duplicate</v-icon>
                                                        duplicate
                                                    </div>
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="isZipFile(item)">
                                            <v-list-item-title>
                                                <v-btn :loading="extractingZip" @click="extractZip(item)" color="secondary" text block class="justify-start">
                                                    <v-icon left>mdi-folder-zip-outline</v-icon>
                                                    extract all
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-divider class="my-3"></v-divider>
                                    </template>
                                    <v-list-item v-if="isDevelopment && !isColdStorage && !isSpaceRested" e2e-delete-file-for-me>
                                        <delete-button
                                            objectType="file"
                                            :buttonName="getDeleteLabel"
                                            protocol="POST"
                                            :request-body="{ fids: [item.fid], mode: 0 }"
                                            apiURL="/files/delete_async"
                                            :isAsyncDeletion="true"
                                            :showDialog="true"
                                            :warningText="`Are you sure you want to delete - ${item.short_id.toUpperCase()} - permanently?`"
                                            @error="errorMessage($event.error)"
                                            @deleting="emptySelected(!$event.value)"
                                            fetchString="snapshotStore/fetchCurrentFiles"
                                            :objectName="item.short_id"></delete-button>
                                    </v-list-item>
                                    <template
                                        v-if="
                                            isSpaceAdmin &&
                                            !isHomeFilesArea &&
                                            currentSpaceType !== spaceTypes.RESEARCH_SPACE &&
                                            !isColdStorage &&
                                            !isSpaceRested
                                        ">
                                        <v-list-item e2e-delete-file-for-others>
                                            <delete-button
                                                objectType="file"
                                                :buttonName="getDeleteForOthersLabel"
                                                protocol="POST"
                                                :request-body="{ fids: [item.fid], mode: 2 }"
                                                apiURL="/files/delete_async"
                                                :isAsyncDeletion="true"
                                                :showDialog="true"
                                                :warningText="`Are you sure you want to delete all distributed versions of - ${item.short_id.toUpperCase()} - from the other instances permanently? The file would still be available for you.`"
                                                @error="errorMessage($event.error)"
                                                @deleting="emptySelected(!$event.value)"
                                                fetchString="snapshotStore/fetchCurrentFiles"
                                                :objectName="item.short_id"></delete-button>
                                        </v-list-item>
                                        <v-list-item v-if="isDevelopment && isMasterInstance && !isSpaceRested" e2e-delete-file-for-all>
                                            <delete-button
                                                objectType="file"
                                                buttonName="Delete for all"
                                                protocol="POST"
                                                :request-body="{ fids: [item.fid], mode: 1 }"
                                                apiURL="/files/delete_async"
                                                :isAsyncDeletion="true"
                                                :showDialog="true"
                                                :warningText="`Are you sure you want to delete your and all distributed versions of - ${item.short_id.toUpperCase()} - permanently?`"
                                                @error="errorMessage($event.error)"
                                                @deleting="emptySelected(!$event.value)"
                                                fetchString="snapshotStore/fetchCurrentFiles"
                                                :objectName="item.short_id"></delete-button>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
        </v-card>
        <v-container v-if="readme_md_content && !isSpaceRested">
            <div class="d-flex justify-center w-100">
                <v-card class="mt-3 w-100" outlined>
                    <v-card-title>
                        <v-icon left>mdi-markdown</v-icon>
                        README.md
                    </v-card-title>
                    <v-card-text>
                        <MarkdownViewer :editorText="readme_md_content" id="files" />
                    </v-card-text>
                </v-card>
            </div>
        </v-container>
    </div>
</template>

<script>
import DeleteButton from '@/components/DeleteButton.vue'
import { mapGetters, mapState } from 'vuex'
import { humanFileSize } from '@/utils'
import 'github-markdown-css/github-markdown.css'
import 'highlight.js/styles/xcode.css'
import { enumsData } from '@/mixins/enums'
import files from '@/mixins/files'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import Shepherd from 'shepherd.js'
import { fetchTask } from '@/apis'
import path from 'path'
import { snapshotEnums } from '@/mixins/snapshot'

const FileUpload = () => import('@/components/FileUpload.vue')
const RenameButton = () => import('../components/TheSnapshotRenameButton.vue')
const FileStatus = () => import('../components/TheSnapshotFileStatus')
const PdfViewer = () => import('../components/PdfViewer.vue')
const NotebookViewer = () => import('@/modules/snapshot/components/NotebookViewer.vue')
const MarkdownViewer = () => import('@/components/MarkdownViewer')
const CreateFolder = () => import('../components/TheSnapshotCreateFolder.vue')
const TheSnapshotMoveAndCopyFiles = () => import('../components/TheSnapshotMoveAndCopyFiles')
const TheSnapshotFileEditor = () => import('../components/TheSnapshotFileEditor.vue')
const TheSnapshotUsageStatisticsDialog = () => import('../components/TheSnapshotUsageStatisticsDialog.vue')
const IncreaseStorageQuota = () => import('../components/IncreaseStorageQuota.vue')
const VimeoUploadDialog = () => import('../components/VimeoUploadDialog.vue')
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

function initialState() {
    return {
        search: '',
        headers: [
            { text: 'File', align: 'left', value: 'short_id', class: 'pl-8' },
            {
                text: 'Last modified',
                value: 'last_modified_timestamp',
                width: '235px'
            },
            { text: 'Size', value: 'size', align: 'end' },
            { text: 'Actions', align: 'right', value: 'actions', width: '200px' }
        ],
        selected: [],
        filesToMoveOrCopy: [],
        folderTypeSelected: 'files',
        numFilesToDelete: 0,
        itemsPerPageOptions: [25, 50, 100, -1],
        currentPathString: '',
        downloading: [],
        tableOptions: {
            page: 0,
            itemsPerPage: 25,
            pageStart: 1,
            pageStop: 25,
            itemsLength: 25
        },
        currentFilesVisible: [],
        multipleFilesActionsMenu: false,
        closeDelay: 100,
        showHidden: false,
        show: false,
        readme_md_content: '',
        readmeMDLastModified: '',
        stageSuggestionSnackbar: false,
        snackbarTimeout: 10000,
        denseTable: false,
        panel: 1,
        error: false,
        errorContent: '',
        expandedTable: [],
        filesBatchData: [],
        previewBlobUrl: '',
        notebookContent: null,
        nbFile: '',
        previewLongId: '',
        downloadingFileForPreview: false,
        copyOrMoveOperation: null,
        fileEditContent: '',
        zipSupportedFileExtensions: ['zip', 'gz', 'tar', 'tgz', 'tar.gz', 'bz2'],
        extractingZip: false,
        editorLanguages: {
            cpp: 'cpp',
            css: 'css',
            html: 'html',
            ini: 'ini',
            java: 'java',
            js: 'javascript',
            json: 'json',
            md: 'markdown',
            php: 'php',
            py: 'python',
            r: 'r',
            scala: 'scala',
            sh: 'shell',
            sql: 'sql',
            yaml: 'yaml'
        },
        stagingTour: null,
        dropdownMenu: {},
        mimeType: '',
        isEditableFileFetching: false
    }
}

export default {
    name: 'SnapshotFiles',
    mixins: [enumsData, appTypeAndImageLink, files, snapshotEnums],
    data: () => {
        return initialState()
    },
    computed: {
        endpoint() {
            const snid = this.$route.params.snid
            const type = this.folderTypeSelected === this.folderTypesLabels.PERSONAL_FILES ? this.fileAreaType + '_v2' : this.fileAreaType
            return `/snapshots/${snid}/fs/${type}` + this.pathArray.join('/')
        },
        dirPaths() {
            return this.pathArray.map((item, i) => {
                return { text: item, index: i }
            })
        },
        quotaPercentageUsage() {
            if (this.snapshotQuota) {
                return this.snapshotQuota.bytes_pcent || 0
            }
            return null
        },
        snapshotFilesystemPrefix() {
            return this.$store.getters['snapshotStore/snapshotFilesystemPrefixById'](this.$route.params.snid)
        },
        ...mapState('snapshotStore', [
            'showUploadSnackbar',
            'stagingObjects',
            'filesFetching',
            'fetchingSnapshotQuota',
            'lastUploadedFiles',
            'currentTables',
            'restoringSnapshot',
            'snapshotQuota',
            'fileAreaType',
            'applications',
            'pathArray',
            'currentFiles',
            'currentFolder'
        ]),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapGetters('spaceStore', [
            'currentSpaceType',
            'isSpaceAdmin',
            'isSpaceArchived',
            'isSpacePreResting',
            'isSpaceRested',
            'isSpaceResting',
            'isSpaceInAnyRestState',
            'isSpaceWaking'
        ]),
        ...mapState('spaceStore', ['currentSpace']),
        ...mapState(['userInfo']),
        ...mapState('tourStore', ['stageTourStarted']),
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isMasterInstance', 'isDistributedInstance', 'isInstanceViewer']),
        selectedFileIds() {
            return this.selected.map(file => file.fid)
        },
        selectedFileNames() {
            return this.selected.map(file => file.short_id)
        },
        getDeleteForOthersLabel() {
            return this.isDistributedInstance && this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE ? 'Delete for students' : 'Delete for others'
        },
        isHomeFilesArea() {
            return this.fileAreaType === this.folderTypesLabels.PERSONAL_FILES
        },
        jupyterApps() {
            return this.applications.filter(app => {
                let jupyterApp = null
                if (app.image_families.includes('JupyterLab')) {
                    if (this.folderTypeSelected !== this.folderTypesLabels.PERSONAL_FILES) {
                        jupyterApp = app
                    }
                    if (this.folderTypeSelected === this.folderTypesLabels.PERSONAL_FILES && app.file_content_manager_root_dir === '/' && !this.isSysadmin) {
                        jupyterApp = app
                    }
                }
                return jupyterApp
            })
        },
        getDeleteLabel() {
            return this.isSpaceAdmin && this.isMasterInstance && !this.isHomeFilesArea && this.currentSpaceType !== this.spaceTypes.RESEARCH_SPACE
                ? 'Delete for me'
                : 'Delete'
        },
        tableData() {
            if (this.filesFetching) return []
            if (this.showHidden) return this.sortFilesList(this.currentFiles.slice())
            const filteredData = this.currentFiles.filter(f => {
                return !f.short_id.startsWith('.')
            })
            return this.sortFilesList(filteredData)
        },
        isSysadmin() {
            return this.userInfo && this.userInfo.is_sysadmin
        },
        isColdStorage() {
            return this.$store.getters['snapshotStore/isColdStorage'](this.$route.params.snid)
        }
    },
    methods: {
        isZipFile(file) {
            const fileExtension = file.short_id.toLowerCase().split('.').pop()
            return this.zipSupportedFileExtensions.includes(fileExtension)
        },
        async extractZip(file) {
            this.extractingZip = true
            try {
                const { data } = await this.$axios.post(`files/${file.fid}/unzip`)
                const taskResult = await fetchTask(data.tkid)
                if (taskResult instanceof Error) {
                    throw taskResult
                } else {
                    this.fetchFiles()
                }
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Failed to extract files.`,
                    snackBarTimeout: 10000,
                    snackBarIcon: 'error'
                })
            }
            this.extractingZip = false
        },
        fetchFiles() {
            this.emptySelected(true)
            this.$store.dispatch('snapshotStore/fetchCurrentFiles', {
                id: this.$route.params.snid,
                route: this.$route,
                setFetchingStatus: true
            })
        },
        isFolder(object) {
            return object.type === this.nuvolosObjectTypes.FOLDER
        },
        closeMenu(file) {
            this.dropdownMenu[`file_${file.fid}`] = false
        },
        confirmQuestion(type) {
            const ul = document.createElement('ul')
            this.selected.forEach(file => {
                const li = document.createElement('li')
                li.appendChild(document.createTextNode(file.short_id))
                ul.appendChild(li)
            })
            const tmp = document.createElement('div')
            tmp.appendChild(ul)
            switch (type) {
                case 0:
                    return `Are you sure you want to delete: ${tmp.innerHTML} permanently?`
                case 1:
                    return `Are you sure you want to delete your and all distributed versions of: ${tmp.innerHTML} permanently?`
                case 2:
                    return `Are you sure you want to delete all distributed versions of: ${tmp.innerHTML} from the other instances permanently? The files would still be available for you.`
            }
            return `Are you sure you want to delete: ${tmp.innerHTML} permanently?`
        },
        restoreSnapshotToCurrentState: function () {
            this.$store.dispatch('snapshotStore/restoreSnapshotToCurrentState', { snid: this.$route.params.snid, createBackup: true })
        },
        resetInitialState: function () {
            Object.assign(this.$data, initialState())
        },
        humanFileSize: function (bytes, si) {
            return humanFileSize(bytes, si)
        },
        goToSnapshots() {
            this.$router.push({
                name: 'instance-snapshots',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid
                }
            })
        },
        async fetchIsEditableFile(file) {
            if (this.isColdStorage) return

            this.isEditableFileFetching = true
            this.mimeType = ''
            try {
                if (!this.isFolder(file)) {
                    const { data } = await this.$axios.get(`/files/${file.fid}/mime_type`)
                    this.mimeType = data.mime_type
                }
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Failed to fetch if file is editable or not.`,
                    snackBarTimeout: 10000,
                    snackBarIcon: 'error'
                })
            }
            this.isEditableFileFetching = false
        },
        fileLanguage(fileData) {
            const fileExtension = fileData.short_id.toLowerCase().split('.').pop()
            const availableLanguages = Object.keys(this.editorLanguages)
            if (availableLanguages.includes(fileExtension)) {
                return this.editorLanguages[fileExtension]
            } else {
                return ''
            }
        },
        sortFilesList(files) {
            const sortedByDate = files.sort(function (a, b) {
                return new Date(b.last_modified_timestamp) - new Date(a.last_modified_timestamp)
            })
            const foldersList = sortedByDate.filter(file => this.isFolder(file))
            const filesList = sortedByDate.filter(file => !this.isFolder(file))
            return foldersList.concat(filesList)
        },
        changeFileType(type) {
            if (type === this.folderTypesLabels.WORKSPACE_FILES) {
                this.folderTypeSelected = this.folderTypesLabels.WORKSPACE_FILES
                this.$router.push({
                    name: 'snapshot-files',
                    params: {
                        oid: this.$route.params.oid,
                        sid: this.$route.params.sid,
                        iid: this.$route.params.iid,
                        snid: this.$route.params.snid,
                        fileArea: this.folderTypesLabels.WORKSPACE_FILES
                    }
                })
            } else if (type === this.folderTypesLabels.PERSONAL_FILES) {
                this.folderTypeSelected = this.folderTypesLabels.PERSONAL_FILES
                this.$router.push({
                    name: 'snapshot-files',
                    params: {
                        oid: this.$route.params.oid,
                        sid: this.$route.params.sid,
                        iid: this.$route.params.iid,
                        snid: this.$route.params.snid,
                        fileArea: this.folderTypesLabels.PERSONAL_FILES
                    }
                })
            }
        },
        openDirectory: function (shortId) {
            this.selected = []
            this.$store.dispatch('snapshotStore/openDirectory', {
                nextDirectory: shortId
            })
        },
        clearCopyAndMoveFiles: function () {
            if (this.filesToMoveOrCopy.length) {
                this.filesToMoveOrCopy = []
            }
        },
        removeFileFromMoveCopyList: function (fid) {
            if (fid) {
                this.filesToMoveOrCopy = this.filesToMoveOrCopy.filter(file => file.fid !== fid)
            }
        },
        addToMoveCopyList: function (filesArray, operation, multipleSelection = false) {
            if (!this.copyOrMoveOperation || operation !== this.copyOrMoveOperation) {
                this.copyOrMoveOperation = operation
                this.filesToMoveOrCopy = []
            }
            const selectedFilesIds = this.filesToMoveOrCopy.map(file => file.fid)
            if (filesArray.length) {
                filesArray.forEach(file => {
                    if (!selectedFilesIds.includes(file.fid)) {
                        this.filesToMoveOrCopy.push(file)
                    }
                })
                if (multipleSelection) {
                    this.selected = []
                }
            }
        },
        duplicateFile: function (fileData) {
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            const successMessage = `${fileData.short_id.toUpperCase()} successfully submitted for duplication, you can track its progress <a href="${userTasksRoute}">here</a>`
            this.$axios
                .post(`/files/${fileData.fid}/duplicate_async`)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: successMessage,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                    this.$store.dispatch('userStore/fetchUserTasks')
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to duplicate ${fileData.short_id}`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
        },
        addToStaging: function (data, dataSource) {
            if (data.length) {
                const stagingObjectsBefore = this.stagingObjects.files.length
                const filesWithPaths = []
                data.forEach(file => {
                    const fileObject = file
                    if (file.local_path !== '') {
                        fileObject.filePath = file.local_path + '/' + file.short_id
                    } else {
                        fileObject.filePath = file.short_id
                    }
                    if (file.type === this.nuvolosObjectTypes.FOLDER) {
                        fileObject.filePath = fileObject.filePath + '/'
                    }
                    fileObject.fileType =
                        this.folderTypeSelected === this.folderTypesLabels.WORKSPACE_FILES
                            ? this.userWorkAreas.WORKSPACE
                            : this.folderTypeSelected === this.folderTypesLabels.PERSONAL_FILES
                            ? this.userWorkAreas.PERSONAL
                            : 'unknown'
                    filesWithPaths.push(fileObject)
                })
                this.$store.dispatch('snapshotStore/updateStagingObjects', {
                    itemType: this.nuvolosObjectTypes.FILE,
                    newItems: filesWithPaths,
                    updateMode: 'add'
                })
                if (dataSource === 'selected') {
                    this.selected = []
                } else if (dataSource === 'uploaded') {
                    this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
                    this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                        newData: [],
                        updateMode: 'clear'
                    })
                }
                const stagingObjectsAfter = this.stagingObjects.files.length
                if (stagingObjectsAfter !== stagingObjectsBefore) {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Files added to stage.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Selected files already staged.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'info'
                    })
                }
            }
            this.panel = 0
            this.stagingTour && this.stagingTour.next()
        },
        discardUploaded: function () {
            this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                newData: [],
                updateMode: 'clear'
            })
            this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
            this.stageSuggestionSnackbar = false
        },
        endsWithAny: function (suffixes, string) {
            return suffixes.some(suffix => {
                return string.endsWith(suffix)
            })
        },
        fileIcon: function (item) {
            const fileType = item.type
            const fileName = item.short_id
            const video = item.is_video
            if (video) {
                return 'mdi-file-video-outline '
            }
            if (fileName) {
                if (fileName.endsWith('.json')) {
                    return 'mdi-code-json'
                } else if (fileName.endsWith('.pdf')) {
                    return 'mdi-adobe-acrobat'
                } else if (this.endsWithAny(['.py', '.R', '.ipynb'], fileName)) {
                    return 'mdi-file-code-outline'
                } else if (this.endsWithAny(['.svg', '.png', '.jpeg', '.jpg', '.eps'], fileName)) {
                    return 'mdi-file-image-outline'
                } else if (this.endsWithAny(['.docx'], fileName)) {
                    return 'mdi-file-word-outline'
                } else {
                    return 'mdi-file-outline'
                }
            } else if (fileType) {
                if (fileType.includes('image')) {
                    return 'mdi-file-image'
                } else if (fileType.includes('application/pdf')) {
                    return 'mdi-adobe-acrobat'
                } else if (fileType.includes('officedocument.wordprocessingml.document')) {
                    return 'mdi-file-word-outline'
                } else if (fileType.includes('application/json')) {
                    return 'mdi-json'
                } else {
                    return 'mdi-file-outline'
                }
            } else {
                return 'mdi-file-outline'
            }
        },
        emptySelected(confirmed) {
            if (confirmed) {
                this.selected = []
            }
        },
        clearMoveList: function () {
            this.filesToMoveOrCopy = []
        },
        async downloadFile(file) {
            this.$store.dispatch('showSnackBar', {
                snackBarText: `Download in progress...`,
                snackBarTimeout: 10000,
                snackBarIcon: 'mdi-progress-download'
            })

            const url = Array.isArray(file) ? await this.fetchMultipleFilesDownloadLink(file) : await this.fetchFileDownloadLink(file)

            if (url instanceof Error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Download failed!`,
                    snackBarTimeout: 10000,
                    snackBarIcon: 'error'
                })
                return
            }

            // Create a link to download it
            const pom = document.createElement('a')
            pom.href = url
            pom.click()
        },
        async fetchFileDownloadLink(file) {
            try {
                const { data } = await this.$axios.get(`/files/${file.fid}/download_link`)
                return `${this.$axios.defaults.baseURL}downloads/${data}`
            } catch (error) {
                return error
            }
        },
        async fetchMultipleFilesDownloadLink(files) {
            const postBody = { fids: files.map(file => file.fid) }
            try {
                const { data } = await this.$axios.post('/files/download_link', postBody)
                return `${this.$axios.defaults.baseURL}/downloads/${data}`
            } catch (error) {
                return error
            }
        },
        previewFile(fileData) {
            this.downloadingFileForPreview = true
            this.$axios.get(`/files/${fileData.fid}/download_link`).then(response => {
                this.$axios
                    .get(`/downloads/${response.data}`, { timeout: 300000, responseType: 'blob' })
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
                        this.previewBlobUrl = url
                        this.previewLongId = fileData.short_id
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Download of ${fileData.short_id} failed!`,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.downloadingFileForPreview = false
                    })
            })
        },
        previewNotebook(fileData) {
            this.downloadingFileForPreview = true
            this.$axios.get(`/files/${fileData.fid}/download_link`).then(response => {
                this.$axios
                    .get(`/downloads/${response.data}`, { timeout: 300000 })
                    .then(({ data }) => {
                        this.notebookContent = data
                        this.nbFile = fileData.short_id
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Download of ${fileData.short_id} failed!`,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                    })
                    .finally(() => {
                        this.downloadingFileForPreview = false
                    })
            })
        },
        setFolderTypeAndFetch(folderType, fileLevel) {
            this.$store.dispatch('snapshotStore/setFolderType', folderType)
            this.$store.dispatch('snapshotStore/setTreeLevel', {
                snid: this.$route.params.snid,
                level: fileLevel
            })
        },
        errorMessage: function (error) {
            if (error) {
                this.error = true
                this.errorContent = 'An error has occurred'
            }
        },
        isAllVideo(files) {
            if (files.length === 0) return
            const videos = files.filter(file => file.is_video)
            return videos.length === files.length
        },
        canEditInJupyter(item) {
            const isIpynb = item.short_id.toLowerCase().split('.').pop() === 'ipynb'
            return isIpynb && this.jupyterApps.length > 0
        },
        createFilePath(item, app) {
            let filePath = ''
            if (app.file_content_manager_root_dir === '/') {
                if (item.area === 'home') {
                    filePath = path.join(item.area, 'datahub', app.file_content_manager_root_dir, item.local_path, item.short_id)
                } else {
                    filePath = path.join(item.area, item.local_path, item.short_id)
                }
            } else {
                filePath = path.join(item.local_path, item.short_id)
            }
            return filePath
        },
        copyToClipboard(item, fullpath = false) {
            const { local_path: path, short_id: filename } = item
            const text = fullpath ? `${path}/${filename}` : filename
            return text.includes(' ') ? `'${text}'` : text
        },
        showFileVersions(file) {
            this.$router.push({
                name: 'snapshot-file-diff',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid,
                    fid: file.fid
                }
            })
        }
    },
    created() {
        this.folderTypeSelected = this.$store.state.snapshotStore.fileAreaType
    },
    watch: {
        folderTypeSelected(to, from) {
            const level = this.$route.params.localPath === undefined ? 1 : this.$route.params.localPath.split('/').length
            this.setFolderTypeAndFetch(to, level)
        },
        showUploadSnackbar: function (nextValue, prevValue) {
            this.stageSuggestionSnackbar = nextValue
        },
        stageTourStarted(val) {
            if (val) {
                this.stagingTour = new Shepherd.Tour({
                    useModalOverlay: true,
                    defaultStepOptions: {
                        scrollTo: { behavior: 'smooth' }
                    }
                })
                this.stagingTour.addStep({
                    title: 'Staging files',
                    text: `Click on the Stage button to stage a given file/folder.`,
                    attachTo: {
                        element: '.shepherd-staging-step-1',
                        on: 'top'
                    },
                    id: 'staging-files-1',
                    buttons: [
                        {
                            text: 'Ok',
                            action: this.stagingTour.complete
                        }
                    ]
                })
                this.stagingTour.addStep({
                    title: 'Staging files',
                    text: `Once you have staged all the files you would like to distribute (you can add multiple files / folders to the stage), \
                    you can access the stage here to continue with the distribution process.`,
                    attachTo: {
                        element: '.shepherd-staging-step-3',
                        on: 'right'
                    },
                    id: 'staging-files-3',
                    buttons: [
                        {
                            text: 'Got it!',
                            action: this.stagingTour.complete
                        }
                    ]
                })
                this.stagingTour.start()
                Shepherd.on('complete', () => {
                    this.stagingTour = null
                    this.$store.commit('tourStore/stageTourStarted', false)
                })
            }
        },
        filesFetching(newVal, oldVal) {
            // started fetching
            if (this.stageTourStarted && this.stagingTour && newVal && !oldVal) {
                this.$nextTick(() => {
                    this.stagingTour.cancel()
                })
            }
            // finished fetching
            if (this.stageTourStarted && this.stagingTour && !newVal && oldVal) {
                this.$nextTick(() => {
                    this.stagingTour.start()
                })
            }
        },
        currentFiles: {
            handler(to, from) {
                this.selected = to.filter(i => this.selected.filter(a => a.fid === i.fid).length)
                const readmeMD = to.filter(f => {
                    return f.short_id && f.short_id.toLowerCase() === 'readme.md'
                })
                if (readmeMD.length) {
                    if (readmeMD[0].last_modified !== this.readmeMDLastModified) {
                        this.$axios
                            .get(`/files/${readmeMD[0].fid}/download`, {
                                timeout: 300000,
                                responseType: 'blob' // important
                            })
                            .then(response => {
                                const reader = new FileReader()
                                reader.addEventListener('loadend', e => {
                                    const text = e.target.result
                                    this.readme_md_content = text
                                })
                                reader.readAsText(response.data)
                            })
                            .catch(e => {
                                console.log(e)
                                this.readme_md_content = ''
                                this.readmeMDLastModified = ''
                            })
                        this.readmeMDLastModified = readmeMD[0].last_modified_timestamp
                    }
                } else {
                    this.readme_md_content = ''
                    this.readmeMDLastModified = ''
                }
            },
            immediate: true
        },
        $route: {
            handler(to, from) {
                if (to && from && to.params.snid !== from.params.snid) {
                    this.resetInitialState()
                }
                if (to.name !== 'snapshot-files') {
                    this.selected = []
                    this.search = ''
                    this.$store.dispatch('snapshotStore/setShowUploadSnackbar', false)
                    this.$store.dispatch('snapshotStore/updateLastUploadedFiles', {
                        newData: [],
                        updateMode: 'clear'
                    })
                    this.clearMoveList()
                }
            },
            immediate: true
        }
    },
    components: {
        FileUpload,
        RenameButton,
        DeleteButton,
        FileStatus,
        PdfViewer,
        CreateFolder,
        TheSnapshotMoveAndCopyFiles,
        TheSnapshotFileEditor,
        MarkdownViewer,
        TheSnapshotUsageStatisticsDialog,
        VimeoUploadDialog,
        CopyToClipboard,
        NotebookViewer,
        IncreaseStorageQuota
    }
}
</script>

<style lang="scss" scoped>
.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 980px;
    padding: 45px;
}

@media (max-width: 767px) {
    .markdown-body {
        padding: 15px;
    }
}
::v-deep .v-data-footer__pagination {
    margin-left: 0px;
    margin-right: 3px;
}

.v-card.pointer {
    transition: background-color 0.2s ease-in-out;
}
</style>
