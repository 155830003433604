import * as dompurify from 'dompurify'
import 'prismjs/themes/prism.css'
import Prism from 'prismjs'
import 'prismjs/components/prism-markdown.js'
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css'
import { kebabCase } from 'lodash'

export const toastEditor = {
    props: {
        fetchingFile: { type: Boolean, defualt: true },
        editorText: { type: String, default: '' },
        id: { type: String }
    },
    data() {
        return {
            defaultOptions: {
                plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
                customHTMLSanitizer: html => {
                    return (
                        dompurify.sanitize(html, {
                            ADD_TAGS: ['iframe'],
                            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target']
                        }) || ''
                    )
                },
                customHTMLRenderer: {
                    htmlBlock: {
                        iframe(node) {
                            return [
                                { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
                                { type: 'html', content: node.childrenHTML },
                                { type: 'closeTag', tagName: 'iframe', outerNewLine: true }
                            ]
                        }
                    }
                },
                toolbarItems: [
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                    ['table', 'image', 'link'],
                    ['code', 'codeblock']
                ]
            },
            editorLoaded: false
        }
    },
    created() {
        const self = this

        this.defaultOptions.toolbarItems.push([this.createAnchorButton()])

        this.defaultOptions.customHTMLRenderer.heading = function (node, context) {
            return {
                type: context.entering ? 'openTag' : 'closeTag',
                tagName: `h${node.level}`,
                classNames: [`heading-${node.level}`],
                attributes: {
                    id: kebabCase(`${self.id}-${node.firstChild.literal}`)
                }
            }
        }

        this.defaultOptions.customHTMLRenderer.link = function (node, context) {
            let url = node.destination
            const validUrl = URL.canParse(node.destination)
            if (!validUrl) {
                url = '#' + kebabCase(`${self.id}-${node.destination}`)
            }

            return {
                type: context.entering ? 'openTag' : 'closeTag',
                tagName: `a`,
                attributes: {
                    href: url
                }
            }
        }
    },
    methods: {
        loaded(evt) {
            this.editorLoaded = true
        },
        createAnchorButton() {
            const button = document.createElement('button')
            button.className = 'toastui-editor-toolbar-icons last ' + `${this.id}-toast-anchor-link`
            button.style.backgroundImage = 'none'
            button.style.margin = '0'
            button.innerHTML = `⚓`

            return {
                el: button,
                // command: 'link',
                tooltip: 'Link to heading'
            }
        }
    },
    computed: {
        allLoaded() {
            return !this.fetchingFile && this.editorLoaded
        }
    },
    watch: {
        allLoaded(loaded) {
            if (loaded) {
                // Get the proper anchor button and add a click event listener because we only have the editor instance at this point available
                const button = document.querySelector(`.${this.id}-toast-anchor-link`)
                const editor = this.$refs.toastuiEditor

                button.addEventListener('click', async () => {
                    const text = prompt('Enter anchor text')
                    const url = kebabCase(prompt('Target header text'))
                    if (!url) return
                    const insert = () => {
                        editor.invoke('insertText', `[${text}](#${url})\n`)
                    }
                    const isMarkdownMode = editor.invoke('isMarkdownMode')
                    if (isMarkdownMode) {
                        // In Markdown mode
                        insert()
                    } else {
                        // In WYSIWYG mode
                        await editor.invoke('changeMode', 'markdown')
                        insert()
                        await editor.invoke('changeMode', 'wysiwyg')
                    }
                })

                if (this.editorText) {
                    this.$refs.toastuiEditor.invoke('setMarkdown', this.editorText)
                }
                if (this.placeholderText) {
                    this.$refs.toastuiEditor.invoke('setPlaceholder', this.placeholderText)
                }
            }
        }
    },
    mounted() {
        const readmeDom = this.$refs.toastuiEditor.$el
        const hashLinks = readmeDom.querySelectorAll('a[href^="#"]')
        hashLinks.forEach(link => {
            const anchor = link.getAttribute('href')
            const targetDom = readmeDom.querySelector(anchor)
            link.addEventListener('click', event => {
                event.preventDefault()
                this.$vuetify.goTo(targetDom, {
                    duration: 500,
                    easing: 'easeInOutCubic'
                })
            })
        })
    }
}
