<template>
    <v-input hide-details>
        <v-icon slot="prepend" class="mt-1">mdi-clock-time-four-outline</v-icon>
        <v-container fluid class="pa-0">
            <v-row align="center">
                <v-col>
                    <v-select label="hour" :items="hours" v-model="hour" item-disabled="disabled" @input="handleInput" outlined dense hide-details></v-select>
                </v-col>
                <v-col>
                    <v-select label="minute" :items="minutes" v-model="minute" @input="handleInput" outlined dense hide-details></v-select>
                </v-col>
                <v-col v-if="useSeconds">
                    <v-select label="second" :items="seconds" v-model="second" @input="handleInput" outlined dense hide-details></v-select>
                </v-col>
                <v-col v-if="meridiem">
                    <v-select :items="['AM', 'PM']" v-model="ampm" @input="handleInput" outlined dense hide-details></v-select>
                </v-col>
            </v-row>
        </v-container>
    </v-input>
</template>

<script>
import { dateTimeConcat } from '@/utils'
import { range, padStart } from 'lodash'
import eventBus from '@/components/EventBus'
import { DateTime } from 'luxon'

const padToTwo = string => padStart(string, 2, '0')

const disableDropdownItem = ({ date, hour, minute, second, min, max }) => {
    const currentDropdownItem = DateTime.fromISO(`${date}T${padToTwo(hour)}:${padToTwo(minute)}:${padToTwo(second)}`)
    return currentDropdownItem <= DateTime.fromISO(min) || currentDropdownItem > DateTime.fromISO(max)
}

export default {
    name: 'TimePicker',
    props: {
        min: String,
        max: String,
        value: String,
        date: String,
        useSeconds: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: '24hr'
        }
    },
    data() {
        return {
            hour: null,
            minute: null,
            second: null,
            selectedDate: '',
            ampm: 'AM',
            meridiem: false
        }
    },
    computed: {
        hours() {
            const hours = range(0, this.meridiem ? 12 : 24).map(hour => ({
                text: hour,
                value: hour,
                disabled: disableDropdownItem({
                    date: this.date,
                    hour: this.meridiem ? hour + 12 : hour,
                    minute: this.minute,
                    second: this.second,
                    min: this.min,
                    max: this.max
                })
            }))
            return hours
        },
        minutes() {
            const minutes = range(0, 60).map(minute => ({
                text: padStart(minute.toString(), 2, '0'),
                value: minute,
                disabled: disableDropdownItem({
                    date: this.date,
                    hour: this.meridiem ? this.hour + 12 : this.hour,
                    minute,
                    second: this.second,
                    min: this.min,
                    max: this.max
                })
            }))
            return minutes
        },
        seconds() {
            const seconds = range(0, 60).map(second => ({
                text: padStart(second.toString(), 2, '0'),
                value: second,
                disabled: disableDropdownItem({
                    date: this.date,
                    hour: this.meridiem ? this.hour + 12 : this.hour,
                    minute: this.minute,
                    second,
                    min: this.min,
                    max: this.max
                })
            }))
            return seconds
        },
        datetime() {
            return dateTimeConcat(this.selectedDate, this.value)
        },
        scheduleTime() {
            if (isNaN(this.hour) || this.hour === null || isNaN(this.minute) || this.minute === null) return null

            const hour = this.meridiem && this.ampm === 'PM' ? this.hour + 12 : this.hour
            let time = `${hour}:${padStart(this.minute, 2, '0')}`

            if (this.useSeconds) {
                if (isNaN(this.seconds) || this.second === null) return null
                time += `:${padStart(this.second, 2, '0')}`
            }

            return time
        }
    },
    methods: {
        handleInput() {
            this.$emit('input', this.scheduleTime)
        }
    },
    created() {
        eventBus.$on('scheduleDateChanged', ({ date }) => {
            this.selectedDate = date
        })
        this.selectedDate = this.date
        this.meridiem = this.format === 'ampm'
        const time = DateTime.fromFormat(this.value, 'H:mm')
        const hourFormat = this.meridiem ? 'h' : 'H'
        this.hour = parseInt(time.toFormat(hourFormat), 10)
        this.minute = parseInt(time.toFormat('m'), 10)
        this.second = parseInt(time.toFormat('s'), 10)
        this.ampm = time.toFormat('a')
    }
}
</script>
