<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card width="500">
            <v-card-title class="justify-center align-center nuvolos-header">
                <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
            </v-card-title>
            <v-card-text class="pt-5">
                <v-alert dense text type="info">
                    A Nuvolos account with
                    <b>{{ email }}</b>
                    already exists. Please log in with that account to accept this invitation.
                </v-alert>
                <div class="text-caption text-center">Redirecting shortly...</div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'invitationToLogin',
    data() {
        return {
            email: ''
        }
    },
    methods: {
        async loginAndReturn() {
            await new Promise(resolve => setTimeout(() => resolve(), 5000))
            this.$router.push({ name: 'login', query: { ...this.$route.query } })
        }
    },
    created() {
        this.email = localStorage.getItem('nvLastEmail')
    },
    mounted() {
        this.loginAndReturn()
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
